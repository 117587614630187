import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getTextCowType } from '../util/cowTypes';
import { cost, costPerKilo } from '../../util/number';
import CowListItemAttributes from '../list/components/CowListItemAttributes';
import Button from '../../util/buttons/components/Button';
import './cow-main-details.scss';
import HeartIcon from '../../util/icons/components/HeartIcon';
import { setVideoPlaying } from '../actions';
import { getVideoPlaying } from '../reducer';
import RegisterInterestModal from '../../user/components/RegisterInterestModal';
import { markFavourite } from '../../user/actions';
import AuthenticationIcon from '../../util/icons/components/AuthenticationIcon';
import CowMainImage from './CowMainImage';
import KeyFactsTickList from '../list/components/KeyFactsTickList';
import moment from 'moment';
import CalvingCowMainDetailsSection from './CalvingCowMainDetailsSection';

const mapDispatchToProps = dispatch => ({
  setVideoPlaying: (play) => dispatch(setVideoPlaying(play)),
  favourite: (cow, isFavourite) => dispatch(markFavourite(cow, isFavourite))
});

const mapStateToProps = state => ({
  videoPlaying: getVideoPlaying(state)
});

class CowMainDetailsSection extends Component {
  constructor (props) {
    super(props);
    this.state = {
      registering: false
    };
    this.playVideo = this.playVideo.bind(this);
    this.stopVideo = this.stopVideo.bind(this);
    this.registerInterest = this.registerInterest.bind(this);
    this.toggleFavourite = this.toggleFavourite.bind(this);
    this.hideRegisterInterest = this.hideRegisterInterest.bind(this);
    this.showCalfFields = this.showCalfFields.bind(this);
  }
  registerInterest () {
    this.setState({ registering: true });
  }
  hideRegisterInterest () {
    this.setState({ registering: false });
  }
  toggleFavourite () {
    this.props.favourite(this.props.cow, !this.props.cow.favourite);
  }
  renderAuthenticationSection () {
    const documents = Object.values(this.props.cow.documents || {});
    if (documents && documents.length) {
      return (
        <Link to={ `${this.props.match.url}/documents` } >
          <div className="authentication-section">
            <AuthenticationIcon />
            <h5 className="authentication-title">Authenticated</h5>
            <p className="num-documents" data-cy="headline-doc-count">
              { documents.length + ' documents' }
            </p>
          </div>
        </Link>
      );
    }
    return null;
  }
  playVideo () {
    this.props.setVideoPlaying(true);
    const videoSection = document.getElementById('cow-video-section');
    if (videoSection) {
      videoSection.scrollIntoView({ behavior: 'smooth' });
    }
  }
  stopVideo () {
    this.props.setVideoPlaying(false);
  }
  showCalfFields () {
    return false;
    // if (this.props.cow.type !== 'Heifers' &&
    //   this.props.cow.type !== 'BreedingCows') return false;
    // if (!this.props.cow.calving) return false;
    // if (this.props.cow.calving.status === 'In calf' ||
    // this.props.cow.calving.status === 'Running with bull' ||
    // this.props.cow.calving.status === 'Calf at foot') return true;
    // return false;
  }
  renderDam () {
    if (this.showCalfFields()) return null;
    if (this.props.cow.ancestors && this.props.cow.ancestors.dam) {
      return (
        <div className='parent' data-cy="parent-dam">
          <span className="title">Dam: </span>
          {this.props.cow.ancestors.dam.name}
        </div>
      );
    }
    return null;
  }
  renderSire () {
    if (this.showCalfFields()) return null;
    if (this.props.cow.ancestors && this.props.cow.ancestors.sire) {
      return (
        <div className='parent' data-cy="parent-sire">
          <span className="title">Sire: </span>
          {this.props.cow.ancestors.sire.name}
        </div>
      );
    }
    return null;
  }
  renderCalfStatusAndSire () {
    if (this.showCalfFields()) {
      let name = null;
      if (this.props.cow.calving.sire && this.props.cow.calving.sire.name) {
        name = this.props.cow.calving.sire.name;
      }
      return (
        <div className='calf-status-and-sire' data-cy="calf-status-and-sire">
          <span className="title">
            { `${this.props.cow.calving.status}${name ? ', sired by: ' : ''}` }
          </span>
          {name}
        </div>
      );
    }
    return null;
  }
  renderCalfDueOrBorn () {
    if (this.showCalfFields()) {
      let title = null;
      let dateStr = null;
      if (this.props.cow.calving.predictedBirth &&
        this.props.cow.calving.status === 'In calf') {
        title = 'calf due: ';
        dateStr = moment(this.props.cow.calving.predictedBirth)
          .format('dddd, MMMM Do YYYY');
      } else if (this.props.cow.calving.birthDate &&
        this.props.cow.calving.status === 'Calf at foot') {
        title = 'calf born: ';
        dateStr = moment(this.props.cow.calving.birthDate)
          .format('dddd, MMMM Do YYYY');
      } else {
        return null;
      }
      return (
        <div className='calf-birth-date' data-cy="calf-birth-date">
          <span className="title">{ title }</span>
          { dateStr }
        </div>
      );
    }
    return null;
  }
  renderAncestryLink () {
    const scrollToAncestry = () => {
      const ancestrySection = document.getElementById('cow-family-tree');
      if (ancestrySection) {
        ancestrySection.scrollIntoView({ behavior: 'smooth' });
      }
    };
    if (this.props.cow.ancestors) {
      if (this.props.cow.ancestors.sire || this.props.cow.ancestors.dam) {
        return (
          <div className="ancestry-link" onClick={scrollToAncestry} >
            { `View ${this.showCalfFields() ? '' : 'full '}ancestry` }
          </div>
        );
      }
    }
    return null;
  }
  renderRegisterInterestModal () {
    if (!this.state.registering) return null;
    return (
      <RegisterInterestModal
        cow={this.props.cow}
        close={this.hideRegisterInterest} />
    );
  }
  renderRegisterInterestButton () {
    if (this.props.cow.availabilityStatus === 'SOLD') {
      return (
        <div className="sold-text">sold</div>
      );
    }
    if (this.props.cow.interestRegistered) {
      return (
        <div className="be-in-touch">
          { 'Interest registered' }
        </div>
      );
    }
    return (
      <Button
        onClick={this.registerInterest}
        testId="purchase-inquiry-button"
        label='Purchase Inquiry' />
    );
  }
  renderPrice () {
    if (this.props.cow.type === 'FinishedCattle') {
      const price = this.props.cow.pricePerKilo ?
        costPerKilo.format(this.props.cow.pricePerKilo / 100) : '£-';
      return (
        <div className='price'>
          <span className="smaller">
            { price }
          </span>
          <span className="per-kilo">/kg</span>
          <span className="carcass-weight">carcass weight</span>
        </div>
      );
    }
    const price = this.props.cow.price ?
      cost.format(this.props.cow.price / 100) : '£-';
    return (
      <div className='price' data-cy="headline-price">
        { price }
      </div>
    );
  }
  render () {
    if (this.props.cow && this.props.cow.calving) {
      return <CalvingCowMainDetailsSection
        cow={ this.props.cow }
        match={ this.props.match }/>;
    }
    const documents = this.props.cow ? this.props.cow.documents : {};
    const comments = this.props.cow && this.props.cow.comments ? (
      <p className="comments">{ this.props.cow.comments }</p>
    ) : null;
    return (
      <article className="cow-detail-section" id='cow-main-details-section'>
        { this.renderRegisterInterestModal() }
        {/* <div className="cow-detail-section-wrapper"> */}
        <CowMainImage cow={ this.props.cow } />
        <div className='top-section'>
          <h5 >
            <span className='breed' data-cy="breed-header">
              { this.props.cow.breed }
            </span>
            <span className="pipe">|</span>
            <span className='offering' data-cy="cow-type-header">
              {getTextCowType(this.props.cow.type)}
            </span>
          </h5>
          <HeartIcon
            onClick={this.toggleFavourite}
            className={this.props.cow.favourite ? 'selected' : null} />
          <h3 data-cy="animal-name-header">{this.props.cow.name}</h3>
          <h6 className="tag" data-cy="animal-tag-header">
            { this.props.cow.tag }
            <span className="pipe">|</span>
            <span className="location" data-cy="animal-location-header">
              { this.props.cow.location }
            </span>
          </h6>
          <CowListItemAttributes cow={this.props.cow} />
          <KeyFactsTickList documents={ documents } />
          { this.renderSire() }
          { this.renderDam() }
          { this.renderCalfStatusAndSire() }
          { this.renderCalfDueOrBorn() }
          { this.renderAncestryLink() }
        </div>
        <div className="bottom-section">
          { comments }
          <div className='price-section'>
            { this.renderPrice() }
            { this.renderRegisterInterestButton() }
          </div>
          <div className="authentication-wrapper">
            { this.renderAuthenticationSection() }
          </div>
        </div>
        {/* </div>
        <div className="border"></div> */}
      </article>
    );
  }
}

CowMainDetailsSection.propTypes = {
  cow: PropTypes.object.isRequired,
  setVideoPlaying: PropTypes.func.isRequired,
  videoPlaying: PropTypes.bool.isRequired,
  favourite: PropTypes.func.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(CowMainDetailsSection);
