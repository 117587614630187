import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './cow-list-item-attributes.scss';
import {
  ageFromDateString,
  monthsStringFromDate,
  timeFromNow
} from '../../../util/datesAndAges';

const CowListItemAttributes = ({ cow }) => {
  let addPipe = false;
  const badges = [];
  if (cow.dob && cow.availabilityStatus !== 'SOLD') {
    const age = cow.type === 'FinishedCattle' ?
      monthsStringFromDate(cow.dob) : ageFromDateString(cow.dob);
    badges.push(
      <Fragment key='age'>
        <span className="text" data-cy="cow-list-item-age">
          { age }
        </span>
      </Fragment>
    );
    addPipe = true;
  }
  if (cow.maturity && ['BreedingCows', 'Heifers'].includes(cow.type)) {

    badges.push(
      <Fragment key="maturity">
        { addPipe ? <span className='pipe'>|</span> : null }
        <span className="text" data-cy="cow-list-item-maturity">
          { cow.maturity }
        </span>
      </Fragment>
    );
    addPipe = true;
  }
  if (cow.birthWeight) {
    badges.push(
      <Fragment key="birthWeight">
        { addPipe ? <span className='pipe'>|</span> : null }
        <span className="text" data-cy="cow-list-item-birth-weight">
          Birth Weight { cow.birthWeight }Kg
        </span>
      </Fragment>
    );
    addPipe = true;
  }
  if (cow.weight) {
    const text = cow.weightDate ?
      `Weight (${timeFromNow(cow.weightDate)}) ${cow.weight}kg` :
      `Weight ${cow.weight}kg`;
    badges.push(
      <Fragment key="weight">
        { addPipe ? <span className='pipe'>|</span> : null }
        <span className="text" data-cy="cow-list-item-weight">
          { text }
        </span>
      </Fragment>
    );
    addPipe = true;
  }
  if (cow.availabilityStatus === 'AVAILABLE' &&
  cow.availableDate &&
  new Date(cow.availableDate) > new Date()) {
    badges.push(
      <Fragment key="availability-date">
        { addPipe ? <span className='pipe'>|</span> : null }
        <span className="text" data-cy="cow-list-item-available-date">
          { 'available: ' + timeFromNow(cow.availableDate) }
        </span>
      </Fragment>
    );
    addPipe = true;
  } else if (cow.availabilityStatus === 'RESERVED') {
    badges.push(
      <Fragment key="availability-date">
        { addPipe ? <span className='pipe'>|</span> : null }
        <span className="text" data-cy="cow-list-item-reserved">
          { 'reserved' }
        </span>
      </Fragment>
    );
    addPipe = true;
  }
  return (<div className='cow-list-item-attributes'>{ badges }</div>);
};

CowListItemAttributes.propTypes = {
  cow: PropTypes.object.isRequired
};

export default CowListItemAttributes;
