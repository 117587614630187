/* eslint-disable max-len */
import React from 'react';
import './marbling-meater-logo.scss';

const MarblingMeaterLogo = () => (
  <svg className="marbling-meater-logo" viewBox="0 0 994.67 233.96">
    <path className="title-one" d="M561,98H544.9V53.31l.78-25h-.26L521.67,98H507L483.39,28.26h-.27L484,53.31V98H467.81V12.29h26L509.21,59l5.45,19.33h.26l5.58-19.2,15.32-46.86H561Z" transform="translate(-2.3 -4.64)"/>
    <path className="title-one" d="M642.94,86.66l-1.3,10.77a18.62,18.62,0,0,1-8.43,1.82q-12.47,0-14.93-9.73a17.67,17.67,0,0,1-8.82,7.26,36.84,36.84,0,0,1-14.15,2.47q-9.46,0-14.79-4.28t-5.33-12.33q0-15.84,25.31-20.51l15.58-3V56a11.46,11.46,0,0,0-2.92-8.24c-1.95-2-4.65-3-8.12-3a16.79,16.79,0,0,0-9.73,2.6q-3.76,2.59-5.19,8.43L576,49.8a21.47,21.47,0,0,1,9.35-13.23q7.26-4.82,18.95-4.81,13.88,0,21.67,6t7.79,17.84V82.25A5.15,5.15,0,0,0,634.9,86a4.92,4.92,0,0,0,3.63,1.17A15.2,15.2,0,0,0,642.94,86.66Zm-31.6-2.27q4.74-2.79,4.74-7.59V70.05l-13.89,3.11A16.63,16.63,0,0,0,595.63,76a6,6,0,0,0-2.14,4.87,5.81,5.81,0,0,0,2,4.74,8.28,8.28,0,0,0,5.45,1.62A20.21,20.21,0,0,0,611.34,84.39Z" transform="translate(-2.3 -4.64)"/>
    <path className="title-one" d="M695.48,33.19l-2.21,15.57a16.38,16.38,0,0,0-19.14,2.67q-4.61,4.6-4.61,12.91V98H651.74V33.06h15.44l1.17,12.72q5.32-14,20.12-14A14.16,14.16,0,0,1,695.48,33.19Z" transform="translate(-2.3 -4.64)"/>
    <path className="title-one" d="M762.94,40.72q7.39,9,7.4,24.92t-7.4,24.72q-7.4,8.9-20.38,8.89a23.14,23.14,0,0,1-13.3-3.83,23.69,23.69,0,0,1-8.5-10.32L720.11,98h-15.7V4.64h17.78V43.7a22.48,22.48,0,0,1,8.17-8.76,22.89,22.89,0,0,1,12.2-3.18Q755.54,31.76,762.94,40.72ZM748.53,80.43q4-5.19,4-14.92t-4-14.93q-4-5.19-11.29-5.19a13.36,13.36,0,0,0-10.51,4.54q-4,4.55-4.54,12.72v5.71q.51,8.31,4.54,12.79a13.46,13.46,0,0,0,10.51,4.47Q744.52,85.62,748.53,80.43Z" transform="translate(-2.3 -4.64)"/>
    <path className="title-one" d="M801,77.32c0,3.11.45,5.28,1.36,6.49s2.57,1.81,5,1.81a19.58,19.58,0,0,0,3.83-.32A29.55,29.55,0,0,0,815,84.2l-1.56,12.33a17.28,17.28,0,0,1-5.45,2,32.36,32.36,0,0,1-6.62.71q-9.48,0-13.83-4.61T783.2,79.91V4.64H801Z" transform="translate(-2.3 -4.64)"/>
    <path className="title-one" d="M821.35,14.24q0-9.6,11-9.6t11,9.6q0,9.48-11,9.48T821.35,14.24ZM841.21,98H823.43V33.06h17.78Z" transform="translate(-2.3 -4.64)"/>
    <path className="title-one" d="M912.83,37.86q6.42,6.11,6.42,17.52V98H901.47V60.58q0-14.8-11.55-14.8a14.31,14.31,0,0,0-10.58,4.41Q875,54.6,875,63.69V98H857.22V33.06h16l.52,11.55a22.35,22.35,0,0,1,8.95-9.6,26.38,26.38,0,0,1,13.24-3.25Q906.41,31.76,912.83,37.86Z" transform="translate(-2.3 -4.64)"/>
    <path className="title-one" d="M990.8,90.43q5.78,4.66,5.77,13.36,0,10.38-9.6,16.23t-25.31,5.84q-16.21,0-24.07-4.29t-7.85-11.68a10.91,10.91,0,0,1,3-7.65q3-3.26,8.83-4.68a16.87,16.87,0,0,1-7-5.12,13.19,13.19,0,0,1,.13-15.9,16.33,16.33,0,0,1,7.52-5.06,19.45,19.45,0,0,1-10.12-17.65,19,19,0,0,1,8-16.16q8-5.91,21.74-5.91a49.18,49.18,0,0,1,13.11,1.69,21.91,21.91,0,0,1,6.56-12q4.86-4.34,13.17-3.82L997,30.34a20.13,20.13,0,0,0-2.73-.14,17,17,0,0,0-8,1.69,9.73,9.73,0,0,0-4.48,4.54,19.3,19.3,0,0,1,9.86,17.4,18.87,18.87,0,0,1-8,16q-8,5.9-21.87,5.9a54.44,54.44,0,0,1-11.68-1.17c-3.37.87-5.06,2.81-5.06,5.84a4.53,4.53,0,0,0,2,4,9.36,9.36,0,0,0,5.45,1.36h21.93Q985,85.75,990.8,90.43Zm-13.69,21c3.29-1.52,4.93-3.44,4.93-5.78a4.47,4.47,0,0,0-2.27-4.09c-1.52-.91-4-1.36-7.46-1.36H954.66q-10.78,0-10.78,5.84,0,3.63,4.81,5.65t14.4,2Q972.18,113.66,977.11,111.39ZM953.29,45.91A10,10,0,0,0,950,53.7a10.15,10.15,0,0,0,3.31,7.91,12.22,12.22,0,0,0,8.5,3,12.42,12.42,0,0,0,8.64-3,10.14,10.14,0,0,0,3.3-7.91,10,10,0,0,0-3.3-7.79,12.42,12.42,0,0,0-8.64-3A12.22,12.22,0,0,0,953.29,45.91Z" transform="translate(-2.3 -4.64)"/>
    <path className="title-two" d="M561,216.82H544.9V172.17l.78-25h-.26l-23.75,69.7H507l-23.62-69.7h-.27l.91,25v44.65H467.81V131.16h26l15.44,46.72,5.45,19.34h.26L520.5,178l15.32-46.85H561Z" transform="translate(-2.3 -4.64)"/>
    <path className="title-two" d="M639,191.12H593q2.72,13.75,16.22,13.76a18.37,18.37,0,0,0,9.22-2.08,11.09,11.09,0,0,0,5.06-6l14.53,5.32A23,23,0,0,1,627.61,214q-7.32,4.08-18.36,4.09-16.1,0-24.92-8.89t-8.82-24.86q0-16,8.56-24.85t24.14-8.89q15.06,0,23.17,8.69t8.11,24.79A45.51,45.51,0,0,1,639,191.12ZM597.76,167.5q-3.82,3.76-4.86,11.16h30.24q-1-7.26-4.94-11.1a13.8,13.8,0,0,0-10.12-3.83C603.75,163.73,600.32,165,597.76,167.5Z" transform="translate(-2.3 -4.64)"/>
    <path className="title-two" d="M715.54,205.53l-1.29,10.77a18.69,18.69,0,0,1-8.44,1.82q-12.45,0-14.93-9.74a17.68,17.68,0,0,1-8.82,7.27,37,37,0,0,1-14.15,2.47q-9.46,0-14.79-4.29t-5.33-12.33q0-15.83,25.32-20.5l15.57-3V174.9a11.44,11.44,0,0,0-2.92-8.24c-2-2-4.65-3-8.12-3a16.79,16.79,0,0,0-9.73,2.59q-3.76,2.6-5.19,8.44l-14.15-6a21.51,21.51,0,0,1,9.35-13.24q7.27-4.8,19-4.8,13.88,0,21.67,6t7.79,17.85v26.6a5.16,5.16,0,0,0,1.17,3.77,5,5,0,0,0,3.63,1.17A15.55,15.55,0,0,0,715.54,205.53Zm-31.6-2.28q4.74-2.77,4.74-7.59v-6.75L674.79,192a16.22,16.22,0,0,0-6.55,2.79,5.94,5.94,0,0,0-2.14,4.87,5.83,5.83,0,0,0,1.94,4.73,8.24,8.24,0,0,0,5.45,1.63A20.13,20.13,0,0,0,683.94,203.25Z" transform="translate(-2.3 -4.64)"/>
    <path className="title-two" d="M767.19,212.79a23.32,23.32,0,0,1-7.86,3.9,34.26,34.26,0,0,1-9.93,1.43q-21.67,0-21.67-19.6V165.16H715.92V151.92h11.81V137l17.78-4.93v19.85h21v13.24h-21v30.5q0,9,9,9a15,15,0,0,0,10-3.64Z" transform="translate(-2.3 -4.64)"/>
    <path className="title-two" d="M836.14,191.12H790.19q2.73,13.75,16.23,13.76a18.33,18.33,0,0,0,9.21-2.08,11.1,11.1,0,0,0,5.07-6l14.53,5.32A23,23,0,0,1,824.78,214q-7.34,4.08-18.36,4.09-16.09,0-24.92-8.89t-8.83-24.86q0-16,8.57-24.85t24.14-8.89q15.06,0,23.17,8.69t8.11,24.79A46.79,46.79,0,0,1,836.14,191.12ZM794.93,167.5q-3.82,3.76-4.87,11.16H820.3q-1-7.26-4.93-11.1a13.82,13.82,0,0,0-10.12-3.83Q798.75,163.73,794.93,167.5Z" transform="translate(-2.3 -4.64)"/>
    <path className="title-two" d="M893,152.05l-2.2,15.58a16.37,16.37,0,0,0-19.15,2.66Q867,174.9,867,183.2v33.62H849.26v-64.9H864.7l1.17,12.72q5.31-14,20.11-14A14.15,14.15,0,0,1,893,152.05Z" transform="translate(-2.3 -4.64)"/>
    <path className="title-two" d="M254.51,89a2.87,2.87,0,0,0-4,.87L183.47,195a28.84,28.84,0,0,0-4.08,10.62,28.38,28.38,0,0,0,56,9.29l20.42-123A2.88,2.88,0,0,0,254.51,89ZM207.75,221.59a12.36,12.36,0,1,1,12.36-12.36A12.35,12.35,0,0,1,207.75,221.59Z" transform="translate(-2.3 -4.64)"/>
    <path className="title-two" d="M279.15,24.35a204.45,204.45,0,0,0-71.75-12.9c-113.09,0-205.1,92-205.1,205.1H57.24V202.66h-19a168.52,168.52,0,0,1,21.09-69L74.15,142,81,129.92l-14.4-8.14a171.18,171.18,0,0,1,52.07-49.92l8.2,14,12-7-8-13.7a168.5,168.5,0,0,1,70.6-18.18l-.09,16,13.9.08.08-16A168.88,168.88,0,0,1,266.8,57.43Z" transform="translate(-2.3 -4.64)"/>
    <path className="light" d="M412.5,216.55c0-87.87-55.55-163-133.38-192.13l-12.32,33-.05,0a168.65,168.65,0,0,1,18.9,8.45L277.5,79.5l11.91,7.16,8.29-13.79a171.15,171.15,0,0,1,51.49,50.4l-14.39,8,6.72,12.16,14.86-8.2a168.38,168.38,0,0,1,20.24,67.39l-18.93,0-.15,13.89Z" transform="translate(-2.3 -4.64)"/>
  </svg>
);

export default MarblingMeaterLogo;
