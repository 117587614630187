import React, { useState } from 'react';
import HeartIcon from '../../util/icons/components/HeartIcon';
import { getTextCowType } from '../util/cowTypes';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { markFavourite } from '../../user/actions';
import './calving-cow-main-details.scss';
import CowMainImage from './CowMainImage';
import { getGradeFromScore } from '../../admin/scoreDefinitions/util';
import GBIGradeShield from '../../util/logos/GBIGradeShield';
import CowListItemAttributes from '../list/components/CowListItemAttributes';
import KeyFactsTickList from '../list/components/KeyFactsTickList';
import { Link } from 'react-router-dom';
import AuthenticationIcon from '../../util/icons/components/AuthenticationIcon';
import { cost } from '../../util/number';
import Button from '../../util/buttons/components/Button';
import RegisterInterestModal from '../../user/components/RegisterInterestModal';
import AncestryAndCalving from './AncestryAndCalving';

const CalvingCowMainDetailsSection = ({ cow, match }) => {
  const [ registering, setRegistering ] = useState(false);
  const dispatch = useDispatch();
  const gradeIcon = (cow && cow.grade && cow.grade !== 'Ungraded') ? (
    <GBIGradeShield grade={ getGradeFromScore(cow.beefQualityIndex) } />
  ) : null;
  const documents = cow.documents || {};
  const authButton = Object.keys(documents).length ? (
    <Link to={ `${match.url}/documents` } >
      <div className="authentication-section">
        <AuthenticationIcon />
        <h5 className="authentication-title">Authenticated</h5>
        <p className="num-documents" data-cy="headline-doc-count">
          { Object.keys(documents).length + ' documents' }
        </p>
      </div>
    </Link>
  ) : null;
  const keyFacts = Object.keys(documents).length ?
    <KeyFactsTickList documents={ documents } /> : null;
  const price = cow.price ? cost.format(cow.price / 100) : '£-';
  let registerInterestButton = (<Button onClick={() => setRegistering(true)}
    testId="purchase-inquiry-button"
    label='Purchase Inquiry' />);
  if (cow.availabilityStatus === 'SOLD') {
    registerInterestButton = <div className="sold-text">sold</div>;
  } else if (cow.interestRegistered) {
    registerInterestButton = (
      <div className="be-in-touch">
        Interest registered
      </div>
    );
  }
  const registerInterestModal = registering ? (
    <RegisterInterestModal cow={cow} close={() => setRegistering(false)} />
  ) : null;
  const comments = cow.comments ? (
    <p className="comments">{ cow.comments }</p>
  ) : null;
  return (
    <article id='calving-cow-top-section' className="cow-detail-section">
      { registerInterestModal }
      <div className="header">
        { gradeIcon }
        <h5 >
          <span className='breed' data-cy="breed-header">
            { cow.breed }
          </span>
          <span className="pipe">|</span>
          <span className='offering' data-cy="cow-type-header">
            {getTextCowType(cow.type)}
          </span>
        </h5>
        <HeartIcon
          onClick={ () => dispatch(markFavourite(cow, !cow.favourite)) }
          className={cow.favourite ? 'selected' : null} />
        <h3 data-cy="animal-name-header">{cow.name}</h3>
        <h6 className="tag" data-cy="animal-tag-header">
          { cow.tag }
          <span className="pipe">|</span>
          <span className="location" data-cy="animal-location-header">
            { cow.location }
          </span>
        </h6>
      </div>
      <CowMainImage cow={ cow } />
      <div className="details">
        <div>
          <CowListItemAttributes cow={cow} />
          <div className="ticks-and-auth">
            { keyFacts }
            { authButton }
          </div>
          <AncestryAndCalving cow={ cow } />
        </div>
      </div>
      <div className="section-footer">
        { comments }
        <div className="price-and-enquiry">
          <div className='price' data-cy="headline-price">
            { price }
          </div>
          { registerInterestButton }
        </div>
      </div>
    </article>
  );
};

CalvingCowMainDetailsSection.propTypes = {
  cow: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

export default CalvingCowMainDetailsSection;
