import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PlayIconLight from '../../../util/icons/components/PlayIconLight';
import PauseIconMedium from '../../../util/icons/components/PauseIconMedium';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import './cow-video-section.scss';
import { videoTitleString } from '../../../util/datesAndAges';
import TickerTape from './TickerTape';
import ViewMarkers from './ViewMarkers';
import { getVideoPlaying } from '../../reducer';
import { setVideoPlaying } from '../../actions';
import analytics from '../../../util/analytics';

const cleanTimestamps = (timestamps, removeOverlaps = false) => {
  if (!timestamps) return null;
  let cleanTimestamps = timestamps.slice();
  cleanTimestamps.sort((a, b) => a.timestamp - b.timestamp);
  let closeTime = 0;
  for (let i = 0; i < cleanTimestamps.length; i++) {
    const timestamp = cleanTimestamps[i];
    let start = timestamp.timestamp - (timestamp.duration / 2);
    if (removeOverlaps && start < closeTime) start = closeTime;
    timestamp.start = start;
    timestamp.end = timestamp.timestamp + (timestamp.duration / 2);
    closeTime = timestamp.end;
  }
  return cleanTimestamps;
};

const mapDispatchToProps = dispatch => ({
  setPlaying: playing => dispatch(setVideoPlaying(playing))
});

const mapStateToProps = state => ({
  playing: getVideoPlaying(state)
});

class CowVideoSection extends Component {
  constructor (props) {
    super(props);
    this.state = {
      currentTime: 0,
      duration: 25,
      hover: false
    };
    this.videoRef = React.createRef();
    this.scrubberRef = React.createRef();
    this.playPause = this.playPause.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleDurationChange = this.handleDurationChange.bind(this);
    this.handleVideoEnded = this.handleVideoEnded.bind(this);
    this.handleMouseEnterVideo = this.handleMouseEnterVideo.bind(this);
    this.handleMouseLeaveVideo = this.handleMouseLeaveVideo.bind(this);
    this.handleScrubberClick = this.handleScrubberClick.bind(this);
    this.handleVideoEnded = this.handleVideoEnded.bind(this);
    this.handlePlayingEvent = this.handlePlayingEvent.bind(this);
    this.handleWaitingEvent = this.handleWaitingEvent.bind(this);
    this.jumpToView = this.jumpToView.bind(this);
    if (this.props.video.timestamps) {
      this.straplines = cleanTimestamps(
        this.props.video.timestamps.straplines, true
      );
      this.views = cleanTimestamps(this.props.video.timestamps.views);
    }
  }
  componentDidMount () {
    if (this.props.playing) {
      this.videoRef.current.scrollIntoView({ behavior: 'smooth' });
      this.autoProgressBar();
    }
    if (this.videoRef.current) {
      this.videoRef.current.addEventListener('ended', () => {
        this.videoRef.current.currentTime = 0;
      });
    }
  }
  componentWillUnmount () {
    this.props.setPlaying(false);
    if (this.videoRef.current) {
      this.videoRef.current.removeEventListener('ended', () => {
        this.videoRef.current.currentTime = 0;
      });
    }
  }
  componentDidUpdate (prevProps) {
    if (this.props.playing === true && prevProps.playing === false) {
      analytics('send', 'event', 'cowVideo', 'play', 'start');
      this.videoRef.current.play();
      this.autoProgressBar();
    } else if (this.props.playing === false && prevProps.playing === true) {
      analytics('send', 'event', 'cowVideo', 'play', 'stop');
      this.videoRef.current.pause();
    }
  }
  autoProgressBar () {
    const bar = document.getElementById('progress-bar');
    const time = document.getElementById('time');
    const updateProgressBar = () => {
      if (!this.props.playing) return;
      const progress =
        this.videoRef.current.currentTime / this.state.duration;
      bar.style = `transform: ScaleX(${progress})`;
      const timeTxt = Math.round(this.videoRef.current.currentTime * 100) / 100;
      time.textContent = '' + timeTxt;
      window.requestAnimationFrame(updateProgressBar);
    };
    window.requestAnimationFrame(updateProgressBar);
  }
  playPause () {
    this.props.setPlaying(!this.props.playing);
  }
  handleTimeChange (event) {
    this.setState({ currentTime: this.videoRef.current.currentTime });
  }
  handleDurationChange (event) {
    this.setState({ duration: this.videoRef.current.duration });
  }
  handleMouseEnterVideo () {
    this.setState({ hover: true });
  }
  handleMouseLeaveVideo () {
    this.setState({ hover: false });
  }
  handleScrubberClick (event) {
    event.stopPropagation();
    const rect = this.scrubberRef.current.getBoundingClientRect();
    const width = rect.width;
    const clickPosX = event.clientX - rect.left;
    const targetTime = this.state.duration * clickPosX / width;
    this.videoRef.current.currentTime = targetTime;
    const time = document.getElementById('time');
    const timeTxt = Math.round(this.videoRef.current.currentTime * 100) / 100;
    time.textContent = '' + timeTxt;
    this.setState({ currentTime: targetTime });
    analytics('send', 'event', 'cowVideo', 'jump', 'scrubber');
  }
  handleVideoEnded (event) {
    this.props.setPlaying(false);
  }
  handlePlayingEvent () {
    this.setState({ loading: false });
  }
  handleWaitingEvent () {
    this.setState({ loading: true });
  }
  jumpToView (targetTime) {
    this.videoRef.current.currentTime = targetTime;
    this.props.setPlaying(false);
    this.setState({ currentTime: targetTime });
  }
  getProgressStyle () {
    let scale = 0;
    try {
      scale = this.videoRef.current.currentTime / this.state.duration;
    } catch (error) { /* */ }
    return {
      transform: `scaleX(${scale})`
    };
  }
  getDateAndAgeText () {
    if (this.props.video.date) {
      return (
        <p className='date-and-age'>
          { videoTitleString(this.props.video.date, this.props.dob) }
        </p>
      );
      // return videoTitleString(this.props.video.date, this.props.dob);
    }
    return null;
  }
  getVideoProps () {
    let props = {
      onTimeUpdate: this.handleTimeChange,
      onDurationChange: this.handleDurationChange,
      onEnded: this.handleVideoEnded,
      onWaiting: this.handleWaitingEvent,
      onPlaying: this.handlePlayingEvent,
      ref: this.videoRef,
      playsInline: true
    };
    if (this.props.playing) {
      props.autoPlay = 'autoplay';
      analytics('send', 'event', 'cowVideo', 'play', 'start');
    }
    if (this.props.video.poster) {
      props.poster = this.props.video.poster;
    }
    return props;
  }
  renderSources () {
    const mp4 = this.props.video.mp4
      ? <source type='video/mp4' src={ this.props.video.mp4 } /> : null;
    const hls = this.props.video.hls
      ? (
        <source type='application/x-mpegURL'
          src={ this.props.video.hls } />
      ) : null;
    // const dash = this.props.video.dash
    //   ? (
    //     <source type='application/dash+xml'
    //       src={ this.props.video.dash } />
    //   ) : null;
    const dash = null; // need to check it actually works
    // const hls = null;
    // const mp4 = null;
    return (
      <Fragment>
        { hls }
        { dash }
        { mp4 }
      </Fragment>
    );
  }
  renderTitles () {
    if (this.props.video.mp4 &&
      this.props.video.mp4.includes('Drone')) {
      return null;
    }
    let headClass = this.state.hover || this.props.playing === false
      ? 'video-header show' : 'video-header';
    return (
      <div className={ headClass }>
        <h3>{ this.props.name }</h3>
        { this.getDateAndAgeText() }
      </div>
    );
  }
  renderTickerTape () {
    if (!this.straplines ||
        (this.straplines.length < 1 && this.props.subTitle)) {
      const straplines = [{
        duration: 1000,
        end: 1000,
        start: 0,
        timestamp: 500,
        label: this.props.subTitle
      }];
      return (
        <TickerTape
          currentTime={this.state.currentTime}
          straplines={straplines} />
      );
    }
    if (this.straplines.length) {
      return (
        <TickerTape
          currentTime={this.state.currentTime}
          straplines={this.straplines} />
      );
    }
  }
  renderSectionHeading () {
    let topClassName = 'gbi-title gbi-only';
    let subTitle = null;
    if (this.props.subTitle && this.straplines && this.straplines.length) {
      subTitle = (
        <h4 className="gbi-sub-title gbi-only">{ this.props.subTitle }</h4>
      );
    } else {
      topClassName += ' lonely';
    }
    const title = this.props.title ?
      <h2 className={ topClassName }>{ this.props.title }</h2> : null;
    return (
      <Fragment>
        { title }
        { subTitle }
      </Fragment>
    );
  }
  renderViewMarkers () {
    if (this.views) {
      return (
        <ViewMarkers
          views={this.views}
          currentTime={this.state.currentTime}
          duration={this.state.duration}
          jumpToFunc={this.jumpToView} />
      );
    }
    return null;
  }
  /* eslint-disable max-len */
  render () {
    const playClass =
    (this.props.playing !== true && this.state.loading !== true) ? 'show' : null;
    const pauseClass =
    (this.props.playing && this.state.hover && this.state.loading !== true)
      ? 'show' : null;
    const loadingClass = this.state.loading ? 'show' : 'hide';
    return (
      <article id='cow-video-section' className="cow-detail-section">
        { this.renderSectionHeading() }
        <figure className='bb-video-section' data-cy="video-section">
          <div
            className='video-wrapper'
            onClick={this.playPause}
            onMouseEnter={ this.handleMouseEnterVideo }
            onMouseLeave={ this.handleMouseLeaveVideo } >
            { this.renderTitles() }
            <div className='play-pause-buttons' data-cy="play-pause-buttons">
              <PauseIconMedium className={pauseClass} />
              <PlayIconLight className={playClass} />
              <LoadingIcon className={loadingClass} />
            </div>
            <div id="time" className="time"></div>
            <video { ...this.getVideoProps() }>
              { this.renderSources() }
            </video>
            <div className='video-footer'>
              <div
                ref={ this.scrubberRef }
                onClick={ this.handleScrubberClick }
                className='scrubber'>
                { this.renderViewMarkers() }
                <div
                  id='progress-bar'
                  style={ this.getProgressStyle() }
                  className='progress-bar'></div>
              </div>
              { this.renderTickerTape() }
            </div>
          </div>
        </figure>
      </article>
    );
  }
}

CowVideoSection.propTypes = {
  video: PropTypes.shape({
    // src: PropTypes.string.isRequired,
    mp4: PropTypes.string,
    dash: PropTypes.string,
    hls: PropTypes.string,
    poster: PropTypes.string,
    date: PropTypes.string,
    timestamps: PropTypes.shape({
      straplines: PropTypes.array,
      views: PropTypes.array
    })
  }).isRequired,
  name: PropTypes.string.isRequired,
  dob: PropTypes.string.isRequired,
  playing: PropTypes.bool,
  setPlaying: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(CowVideoSection);
