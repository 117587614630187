import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable max-len */
const InfoIconThick = ({ onClick, className, testId }) => (
  <svg onClick={ onClick }
    className={ className ? 'info-icon ' + className : 'info-icon' }
    data-cy={ testId }
    viewBox="0 0 98.71 98.71">
    <path d="M50,.64A49.36,49.36,0,1,0,99.36,50,49.36,49.36,0,0,0,50,.64Zm7.49,82.65h-15V45.35h15ZM50,34.65a9.33,9.33,0,1,1,9.33-9.33A9.33,9.33,0,0,1,50,34.65Z" transform="translate(-0.64 -0.64)"/>
  </svg>
);

InfoIconThick.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  testId: PropTypes.string
};

export default InfoIconThick;
