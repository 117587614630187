import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSelectedCow } from '../reducer';
import PropTypes from 'prop-types';
import { setSelectedCow } from '../actions';
import CowMainDetailsSection from './CowMainDetailsSection';
import CowDataSection from './CowDataSection';
import CowVideoSection from '../video/components/CowVideoSection';
import ArrowIcon from '../../util/icons/components/ArrowIcon';
import './cow-page.scss';
import FamilyTree from './FamilyTree';
import GeneticSection from './GeneticSection';
import CowMarbling from './CowMarbling';
import { Helmet } from 'react-helmet';
import { getGradeFromScore } from '../../admin/scoreDefinitions/util';

const mapStateToProps = state => ({
  cow: getSelectedCow(state)
});

const mapDispatchToProps = dispatch => ({
  selectCow: id => dispatch(setSelectedCow(id))
});

export class Cow extends Component {
  componentDidMount () {
    this.props.selectCow(this.props.match.params.cowId);
    window.scrollTo(0, 0);
  }
  componentWillUnmount () {
    this.props.selectCow(null);
  }
  renderVideo () {
    if (this.props.cow.video || this.props.cow.orgVideo) {
      const props = {
        video: this.props.cow.video || this.props.cow.orgVideo,
        dob: this.props.cow.dob,
        name: this.props.cow.name
      };
      return (
        <CowVideoSection { ...props } />
      );
    }
    return null;
  }
  renderFamilyTree () {
    if (this.props.cow.ancestors && Object.values(this.props.cow.ancestors)) {
      return <FamilyTree cow={ this.props.cow } />;
    }
    return null;
  }
  renderMabling () {
    if (this.props.cow.type === 'FinishedCattle' && this.props.cow.IMF) {
      return <CowMarbling animal={ this.props.cow } />;
    }
    return null;
  }
  renderTop () {
    return (
      <CowMainDetailsSection
        match={this.props.match}
        cow={this.props.cow} />
    );
  }
  renderEBVs () {
    if (this.props.cow.type !== 'FinishedCattle') {
      return <GeneticSection cow={this.props.cow} />;
    }
  }
  render () {
    if (!this.props.cow) return <div id='cow-page'></div>;
    const name = this.props.cow.name || this.props.cow.tag;
    const grade = getGradeFromScore(this.props.cow.beefQualityIndex);
    const title = `Authenticate ${name} with the GoodBeef Index`;
    const meta = `See why this animal is graded ${grade}. View scores 
    ancestry, marbling, carbon hoofprint, authentication documents 
    and more with the GoodBeef Index
    `;
    const canon =
      `${window.location.origin}/${window.location.pathname}`;
    const className = this.props.cow.warsonFarmSale ? 'warson-farm-sale' : null;
    return (
      <div id='cow-page' className={ className }>
        <Helmet>
          <title>{ title }</title>
          <meta name="description" content={ meta } />
          <link rel="canonical"
            href={ canon } />
        </Helmet>
        <div className='top-spacer bb-page-wrapper'>
          <div className='back-button'>
            <Link to={this.props.match.url.split('/' + this.props.cow.id)[0]}>
              <ArrowIcon />
              Back to search
            </Link>
          </div>
        </div>
        { this.renderTop() }
        { this.renderEBVs() }
        <CowDataSection match={this.props.match} cow={this.props.cow} />
        { this.renderMabling() }
        { this.renderVideo() }
        { this.renderFamilyTree() }
      </div>
    );
  }
}

Cow.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.shape({
      cowId: PropTypes.string
    })
  }),
  selectCow: PropTypes.func.isRequired,
  cow: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(Cow);
