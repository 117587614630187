import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './ancestry-and-calving.scss';

const getAncestorsMenuOption = ancestors => {
  if (ancestors) {
    if ((ancestors.sire && ancestors.sire.name) ||
      (ancestors.dam && ancestors.dam.name)) {
      const menuOption = {
        title: 'Ancestry',
        center: 30, // position for the triangle
        content: []
      };
      if (ancestors.dam && ancestors.dam.name) {
        menuOption.content.push({
          label: 'Dam',
          content: ancestors.dam.name
        });
      }
      if (ancestors.sire && ancestors.sire.name) {
        menuOption.content.push({
          label: 'Sire',
          content: ancestors.sire.name
        });
      }
      menuOption.content.push({
        label: '',
        content: <a href="#cow-family-tree">View full ancestry</a>
      });
      return menuOption;
    }
  }
  return null;
};

const getRunningWithBullMenuOption = (runningWithBull, start) => {
  if (runningWithBull) {
    const menuOption = {
      title: 'Running with Bull',
      center: start + 61, // position for the triangle
      content: []
    };
    if (runningWithBull.name) {
      let name = runningWithBull.name;
      if (runningWithBull.url) {
        name = (
          <a href={ runningWithBull.url }
            target="_blank"
            rel="noopener noreferrer">{ name }</a>
        );
      }
      menuOption.content.push({
        label: 'Bull Name',
        content: name
      });
    }
    if (runningWithBull.tag) {
      let tag = runningWithBull.tag;
      if (runningWithBull.url) {
        tag = (
          <a href={ runningWithBull.url }
            target="_blank"
            rel="noopener noreferrer">{ tag }</a>
        );
      }
      menuOption.content.push({
        label: 'Bull Tag',
        content: tag
      });
    }
    return menuOption;
  }
  return null;
};

/* this works for inCalf and calfAtFoot
by checking for all attriutes on either
property */
const getCalfMenuOption = (calf, start, menuItem, toCenter) => {
  if (calf) {
    const menuOption = {
      title: menuItem,
      center: start += toCenter,
      content: []
    };
    if (calf.naturalSire) {
      let name = '';
      if (calf.naturalSire.name) {
        name = calf.naturalSire.name;
      } else if (calf.naturalSire.tag) {
        name += calf.naturalSire.tag;
      }
      if (calf.naturalSire.url) {
        name = (
          <a href={ calf.naturalSire.url }
            target="_blank"
            rel="noopener noreferrer">{ name }</a>
        );
      }
      menuOption.content.push({
        label: 'Natural Sire',
        content: name
      });
    } else if (calf.aiSire) {
      let name = '';
      if (calf.aiSire.name) {
        name = calf.aiSire.name;
      } else if (calf.aiSire.tag) {
        name += calf.aiSire.tag;
      }
      if (calf.aiSire.url) {
        name = (
          <a href={ calf.aiSire.url }
            target="_blank"
            rel="noopener noreferrer">{ name }</a>
        );
      }
      menuOption.content.push({
        label: 'AI Sire',
        content: name
      });
      if (calf.sweeperBull) {
        let name = '';
        if (calf.sweeperBull.name) {
          name = calf.sweeperBull.name + ' ';
        }
        if (calf.sweeperBull.tag) {
          name += calf.sweeperBull.tag;
        }
        if (calf.sweeperBull.url) {
          name = (
            <a href={ calf.sweeperBull.url }
              target="_blank"
              rel="noopener noreferrer">{ name }</a>
          );
        }
        menuOption.content.push({
          label: 'Sweeper Bull',
          content: name
        });
      }
    }
    if (calf.predictedBirth) {
      const dateStr = moment(calf.predictedBirth)
        .format('dddd, MMMM Do YYYY');
      menuOption.content.push({
        label: 'Pedicted Birth',
        content: dateStr
      });
    }
    if (calf.scanDate) {
      const dateStr = moment(calf.scanDate)
        .format('dddd, MMMM Do YYYY');
      menuOption.content.push({
        label: 'Scan Date',
        content: dateStr
      });
    }
    if (calf.birthDate) {
      const dateStr = moment(calf.birthDate)
        .format('dddd, MMMM Do YYYY');
      menuOption.content.push({
        label: 'Birth Date',
        content: dateStr
      });
    }
    if (calf.sex) {
      menuOption.content.push({
        label: 'Calf Sex',
        content: calf.sex
      });
    }
    return menuOption;
  }
  return null;
};

const buildAncestryCalvingDataStructure = cow => {
  console.log(cow.calving);
  const menuOptions = [];
  let width = 0;
  const ancestorsMenuOption = getAncestorsMenuOption(cow.ancestors);
  if (ancestorsMenuOption) {
    width += 80;
    menuOptions.push(ancestorsMenuOption);
  }
  const calving = cow.calving;
  if (calving) {
    const runningWithBullMenuOption =
      getRunningWithBullMenuOption(calving.runningWithBull, width);
    if (runningWithBullMenuOption) {
      menuOptions.push(runningWithBullMenuOption);
      width += 142;
    }
    const inCalfMenuOption =
      getCalfMenuOption(calving.inCalf, width, 'In Calf', 22);
    if (inCalfMenuOption) {
      menuOptions.push(inCalfMenuOption);
      width += 64;
    }
    const calfAtFootMenuOption =
      getCalfMenuOption(calving.calfAtFoot, width, 'Calf at Foot', 40);
    if (calfAtFootMenuOption) {
      menuOptions.push(calfAtFootMenuOption);
      width += 100;
    }
  }
  return menuOptions;
};

const AncestryAndCalving = ({ cow }) => {
  const menuOptions = buildAncestryCalvingDataStructure(cow);
  const [currentOption, setCurrentOption] =
    useState(menuOptions[menuOptions.length - 1].title);
  const content = {};
  menuOptions.forEach(option => { content[option.title] = option; });
  const setOpenMenuItem = menuItem => {
    if (menuItem === currentOption) {
      setCurrentOption(null);
    } else {
      setCurrentOption(menuItem);
    }
  };
  const menuItems = menuOptions.map(option => {
    const onClick = () => setOpenMenuItem(option.title);
    let className = 'mini-menu-option';
    if (option.title === currentOption) {
      className += ' active';
    }
    return (<div onClick={ onClick }
      className={ className }
      key={ option.title }>
      { option.title }
    </div>);
  });
  let openSection = (
    <div key="info" className="info closed">
      <div className="content-wrapper"
        key='content-wrapper'
        style={ { height: '0' } }></div>
    </div>
  );
  if (currentOption) {
    const list = content[currentOption].content.map(item => {
      return (
        <div className="content-item" key={ item.label }>
          <span className="label">
            { item.label }
            { item.label ? ': ' : null}
          </span>
          <span className="value">{ item.content }</span>
        </div>
      );
    });
    const triangleStyle = {
      transform: `translateX(${content[currentOption].center - 10}px)` // -10 to center the triangle
    };
    const height = content[currentOption].content.length * 20 + 'px';
    const sectionStyle = { height };
    openSection = (
      <div key="info" className="info open" >
        <div style={ triangleStyle } className="triangle"></div>
        <div className="content-wrapper"
          key='content-wrapper'
          style={ sectionStyle }>
          { list }
        </div>
      </div>
    );
  }
  return (
    <div className="ancestry-and-calving">
      <div className="menu">
        { menuItems }
      </div>
      { openSection }
    </div>
  );
};

AncestryAndCalving.propTypes = {
  cow: PropTypes.object.isRequired
};

export default AncestryAndCalving;
