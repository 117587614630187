import React, { Fragment } from 'react';

/* eslint-disable max-len */
const BeefMarblingInfo = () => (
  <Fragment>
    <h2 className="caption" data-cy="beef-marbling-title">
      Beef Marbling Score
    </h2>
    <p className="intro">Marbling is recognised to deliver beef succulence, tenderness and superior taste eating qualities. The beef marbling score is the % of Intra-muscular fat (IMF) contained in the Rib Eye muscle area. It is the primary influencer of eating quality.</p>
    <div className="intro-divider"></div>
    <p className="standard-para">The Beef Marbling fact sheet compares the USDA, MSA (Meat Standards Australia) and Japanese Wagyu marbling grading regimes.</p>
    <p className="standard-para">The EU and UK do not measure eating quality with specific reference to IMF.</p>
    <p className="standard-para">The USDA and MSA rely on the abattoirs to measure the IMF% and fat cover after slaughter.</p>
    <p className="standard-para">The GoodBeef Index, by contrast, predicts animal IMF% and fat cover, using ultrasound techniques between 12-18 months of age and presents composite standard of eating excellence as follows:</p>
    <p className="standard-para"><span className="highlight">IMF% (measured at 12/13th Rib)</span></p>
    <ul className="standard-bullet">
      <li>{ 'O-1% Select (lean) USDA' }</li>
      <li>{ '2-3% Choice (light marbling)' }</li>
      <li>{ '4-6% Prime (well marbled)' }</li>
      <li>{ '6-8% Elite (abundant marbling) MSA' }</li>
      <li>{ '>8% Supreme (extreme marbling)' }</li>
    </ul>
    <p className="standard-para">for more information read the <a className="standard-link" style={{ cursor: 'pointer' }} href='/good-beef/marbling'>marbling fact sheet</a></p>
  </Fragment>
);

export default BeefMarblingInfo;
