/* eslint-disable max-len */
import React from 'react';
import './genetic-hoofprint-logo.scss';

const GeneticHoofprintLogo = () => (
  <svg className="genetic-hoofprint-logo" viewBox="0 0 991.53 418.26">
    <path className="dark" d="M353.26,127.23V184.4H336.7l-.49-12.94C330,180.63,319.5,186,304.27,186c-29.65,0-50.13-20.31-50.13-55.54,0-34.72,20.48-55.85,52.58-55.85,23.43,0,37.52,8.19,46,26.54l-20.79,9c-3.45-11.48-12.62-17-24.58-17-18.52,0-29.82,13.1-29.82,37.18,0,24.57,10.32,37.52,30.31,37.52,14.59,0,25.89-7.05,25.89-21.46v-2.46H302.14V127.23Z" transform="translate(-4.47 -3.5)"/>
    <path className="dark" d="M449.9,152h-58c2.29,11.79,9.49,17.36,20.48,17.36,9.49,0,15.72-3.6,18-10.15l18.35,6.71C443.52,179,430.9,186,412.4,186c-27.2,0-42.61-15.73-42.61-42.59s14.92-42.59,41.28-42.59c25.57,0,39.49,15.4,39.49,42.26A77,77,0,0,1,449.9,152Zm-58.14-15.73h38.16c-1.8-12.29-8.69-18.84-19-18.84C400.1,117.4,393.56,123.79,391.76,136.24Z" transform="translate(-4.47 -3.5)"/>
    <path className="dark" d="M545.31,130.67V184.4H522.87V137.23c0-14.09-6.06-18.68-14.58-18.68-9.5,0-18.84,6.22-18.84,22.61V184.4H467V102.49h20.15l.82,14.59c5.4-11,15.56-16.22,27.85-16.22C532.86,100.86,545.31,110.85,545.31,130.67Z" transform="translate(-4.47 -3.5)"/>
    <path className="dark" d="M641.37,152h-58c2.29,11.79,9.49,17.36,20.47,17.36,9.49,0,15.72-3.6,18-10.15l18.35,6.71C635,179,622.36,186,603.86,186c-27.19,0-42.6-15.73-42.6-42.59s14.91-42.59,41.28-42.59c25.56,0,39.48,15.4,39.48,42.26A77.07,77.07,0,0,1,641.37,152Zm-58.15-15.73h38.16c-1.8-12.29-8.68-18.84-19-18.84C591.57,117.4,585,123.79,583.22,136.24Z" transform="translate(-4.47 -3.5)"/>
    <path className="dark" d="M714.44,179.33C709.2,183.59,700.67,186,692,186c-16.22,0-27.36-6.71-27.36-24.74V119.2h-14.9V102.49h14.9V83.66l22.44-6.23v25.06h26.55V119.2H687.08v38.5c0,7.86,4.27,11.3,11.3,11.3A18,18,0,0,0,711,164.42Z" transform="translate(-4.47 -3.5)"/>
    <path className="dark" d="M723.63,78.75c0-8,4.6-12.13,13.92-12.13s13.94,4.1,13.94,12.13-4.6,12-13.94,12S723.63,86.77,723.63,78.75ZM748.7,184.4H726.25V102.49H748.7Z" transform="translate(-4.47 -3.5)"/>
    <path className="dark" d="M844,124.93l-21,7.54c-2-9.83-7.21-14.9-15.89-14.9-12.78,0-20,8.84-20,26,0,17.53,7.7,25.72,19.82,25.72,9.83,0,15.39-5.24,17-14.58l20.32,6.55c-3.45,14.58-16.23,24.74-37,24.74-27.2,0-42.59-15.73-42.59-42.59s15.55-42.59,41.77-42.59C829.54,100.86,840,111.51,844,124.93Z" transform="translate(-4.47 -3.5)"/>
    <path className="genetic-brand-color" d="M352.42,226.36V334.48h-22.6V288.94H282.16v45.54H259.55V226.36h22.61v44.4h47.66v-44.4Z" transform="translate(-4.47 -3.5)"/>
    <path className="genetic-brand-color" d="M455.07,293.53c0,26.7-15.56,42.59-41.94,42.59s-41.93-15.89-41.93-42.59,15.57-42.6,41.93-42.6S455.07,266.82,455.07,293.53Zm-61.42,0c0,17.36,6.87,25.88,19.48,25.88s19.5-8.52,19.5-25.88-6.87-25.89-19.5-25.89S393.65,276.16,393.65,293.53Z" transform="translate(-4.47 -3.5)"/>
    <path className="genetic-brand-color" d="M551.17,293.53c0,26.7-15.57,42.59-41.94,42.59s-41.93-15.89-41.93-42.59,15.55-42.6,41.93-42.6S551.17,266.82,551.17,293.53Zm-61.43,0c0,17.36,6.88,25.88,19.49,25.88s19.5-8.52,19.5-25.88-6.88-25.89-19.5-25.89S489.74,276.16,489.74,293.53Z" transform="translate(-4.47 -3.5)"/>
    <path className="genetic-brand-color" d="M594.65,252.57h25.89v16.71h-24.9v65.2H573.19v-65.2H558.94V252.57h14.25v-9.83c0-18.51,12.62-27.68,31-27.68,7.38,0,13.61,1.63,17,4.26l-3.11,14.91c-2.46-1.65-5.25-2.46-9.34-2.46-8.84,0-14.09,3.6-14.09,12.61Z" transform="translate(-4.47 -3.5)"/>
    <path className="genetic-brand-color" d="M715.06,293.36c0,26.87-13.43,42.76-35,42.76-12.12,0-21-6.06-25.72-15.24v48H631.84V252.57h19.82l.82,15.89C657.07,258,666.9,250.93,680,250.93,701.79,250.93,715.06,266.66,715.06,293.36Zm-22.61.17c0-16.71-7.21-25.4-19.17-25.4-11.62,0-19,8.69-19,24.58v1.63c0,15.57,7.2,24.58,19,24.58C685.24,318.92,692.45,310.23,692.45,293.53Z" transform="translate(-4.47 -3.5)"/>
    <path className="genetic-brand-color" d="M787.3,252.73l-2.78,19.66c-2.29-1.47-6.07-2.46-10.32-2.46-9.83,0-19.66,7.21-19.66,22.12v42.43H732.1V252.57h19.49l1.48,16.05c4.25-11.3,12.61-17.69,25.38-17.69C782.07,250.93,785.34,251.59,787.3,252.73Z" transform="translate(-4.47 -3.5)"/>
    <path className="genetic-brand-color" d="M796.22,228.82c0-8,4.59-12.13,13.92-12.13s13.93,4.1,13.93,12.13-4.59,12-13.93,12S796.22,236.84,796.22,228.82Zm25.07,105.66H798.83V252.57h22.46Z" transform="translate(-4.47 -3.5)"/>
    <path className="genetic-brand-color" d="M919.93,280.75v53.73H897.49V287.3c0-14.09-6.07-18.68-14.59-18.68-9.5,0-18.83,6.23-18.83,22.61v43.25H841.62V252.57h20.15l.81,14.58c5.42-11,15.57-16.22,27.86-16.22C907.48,250.93,919.93,260.93,919.93,280.75Z" transform="translate(-4.47 -3.5)"/>
    <path className="genetic-brand-color" d="M996,329.4c-5.25,4.26-13.77,6.72-22.44,6.72-16.22,0-27.36-6.72-27.36-24.74v-42.1H931.3V252.57h14.9V233.74l22.44-6.23v25.06h26.54v16.71H968.64v38.5c0,7.86,4.27,11.3,11.3,11.3a18,18,0,0,0,12.61-4.59Z" transform="translate(-4.47 -3.5)"/>
    <path className="transparent" d="M142.19,172.34,97,172.4l-46.7.06c-7.2,8.87-11.5,19.07-12.53,32.46l59.27-.08,57.75-.09C153.75,191.37,149.42,181.18,142.19,172.34Z" transform="translate(-4.47 -3.5)"/>
    <path className="transparent" d="M100.11,263.77l-37.41.05c9.12,7.55,20.51,14.58,33.72,22.38,13.21-7.84,24.58-14.9,33.67-22.47Z" transform="translate(-4.47 -3.5)"/>
    <path className="transparent" d="M154.83,218.16l-57.74.08L38,218.33c1.06,13,4.95,23.3,11.61,32.1l50.46-.07,43-.06C149.78,241.44,153.84,231.22,154.83,218.16Z" transform="translate(-4.47 -3.5)"/>
    <path className="transparent" d="M96.14,80.48l-27.2,0c8.17,7.4,17.48,13.66,27.22,19.63,9.74-6,19-12.28,27.2-19.71Z" transform="translate(-4.47 -3.5)"/>
    <path className="transparent" d="M97.19,344.32v-.2l26,0c-8-7.1-17.08-13.26-26.75-19.24-9.74,6.06-18.93,12.3-26.95,19.5Z" transform="translate(-4.47 -3.5)"/>
    <path className="transparent" d="M96.22,138.64c-12.45,7.25-23.08,13.7-31.75,20.39L97,159l31,0C119.32,152.28,108.68,145.86,96.22,138.64Z" transform="translate(-4.47 -3.5)"/>
    <path className="light" d="M97.19,344.32l-27.68,0c8-7.2,17.21-13.44,26.95-19.5-5-3.11-10.13-6.19-15.41-9.3s-10.65-6.29-16-9.62C33.54,328.18,4.68,360.11,4.76,421.77c17,0,31.14-11.7,35.62-27.92l42.78-.06a13.4,13.4,0,0,0,13.39-13.42l-52.09.07A85.17,85.17,0,0,1,57,357.78l40.32-.06v-.12h-.08Z" transform="translate(-4.47 -3.5)"/>
    <path className="light" d="M127.91,119c-5.31-3.26-10.65-6.37-15.9-9.41s-10.65-6.22-15.85-9.4c-9.74-6-19-12.23-27.22-19.63l27.2,0,0-13.41-39.71.06c-11.52-14-28.19-22.69-46.33-22.64H9.16c9.56,36,31.64,58.05,55.31,74.55,10.33,7.2,21,13.36,31,19.15l.77.45c12.46,7.22,23.1,13.64,31.8,20.31l-31,0,0,13.41,45.16-.06c7.23,8.84,11.56,19,12.63,32.41l-57.75.09,0,13.4,57.74-.08c-1,13.06-5.05,23.28-11.71,32.14l-43,.06,0,13.41,30,0c-9.09,7.57-20.46,14.63-33.67,22.47l1.4.84c9.72,5.71,20,11.78,30,18.81,30.89-19.38,60.41-44.23,60.34-94.44C188.1,162.06,158.69,137.82,127.91,119Z" transform="translate(-4.47 -3.5)"/>
    <path className="genetic-brand-color" d="M127.83,305.85c-10-7-20.29-13.1-30-18.81l-1.4-.84c-13.21-7.8-24.6-14.83-33.72-22.38l37.41-.05,0-13.41-50.46.07C43,241.63,39.08,231.3,38,218.33l59.07-.09,0-13.4-59.27.08c1-13.39,5.33-23.59,12.53-32.46L97,172.4,97,159l-32.54,0c8.67-6.69,19.3-13.14,31.75-20.39l-.77-.45c-10-5.79-20.65-12-31-19.15C33.74,138,4.4,162.3,4.47,211.67c.07,50.21,29.65,75,60.6,94.26,5.33,3.33,10.7,6.52,16,9.62s10.4,6.19,15.41,9.3c9.67,6,18.77,12.14,26.75,19.24l-26,0v.2h.08l0,13.28,38.71-.06c11.2,14,27.69,22.76,45.65,22.72h1.65C173.6,344.42,151.45,322.43,127.83,305.85Z" transform="translate(-4.47 -3.5)"/>
    <path className="genetic-brand-color" d="M135.74,67l-39.62,0,0,13.41,27.22,0c-8.16,7.43-17.46,13.71-27.2,19.71q7.8,4.77,15.85,9.4c5.25,3,10.59,6.15,15.9,9.41,31.44-22,60.06-53.9,60-115.46-16.69,0-30.75,11.32-35.47,27.15l-43.74.06A13.41,13.41,0,0,0,95.28,44.14l53-.07A86.88,86.88,0,0,1,135.74,67Z" transform="translate(-4.47 -3.5)"/>
    <polygon className="genetic-brand-color" points="92.72 340.81 92.74 354.1 92.82 354.1 92.8 340.81 92.72 340.81"/>
  </svg>
);

export default GeneticHoofprintLogo;
