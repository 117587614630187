import React, { Component } from 'react';
import { getSelectedCow } from '../reducer';
import { setSelectedCow } from '../actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './cow-score-card.scss';
import CloseIcon from '../../util/icons/components/CloseIcon';
import history, { previousPath } from '../../beefbook/beefbookHistory';
import {
  getSignificance,
  getScoreCard,
  getCategory,
  getName} from '../../admin/scoreDefinitions/util';
import { getScoreDefinitions } from '../../admin/scoreDefinitions/reducer';

const mapStateToProps = state => ({
  cow: getSelectedCow(state),
  scoreDefinitions: getScoreDefinitions(state)
});

const mapDispatchToProps = dispatch => ({
  selectCow: id => dispatch(setSelectedCow(id))
});

export class CowScoreCard extends Component {
  constructor (props) {
    super(props);
    this.close = this.close.bind(this);
    this.modalRef = React.createRef();
    this.closeRef = React.createRef();
  }
  componentDidMount () {
    this.props.selectCow(this.props.cowId);
    if (!this.closeRef || this.closeRef.current === null) return;
    this.closeRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  close () {
    if (previousPath() !== null) {
      history.goBack(); // scrolls the page back
    } else {
      const cowURL = this.props.match.url.split('/scorecard')[0];
      history.push(cowURL);
    }
  }
  renderScores () {
    const attribute = this.props.match.params.attribute;
    const category = getCategory(attribute, this.props.scoreDefinitions);
    return getScoreCard(attribute, this.props.scoreDefinitions).map(score => {
      let className = 'score';
      if (score.score === this.props.cow[category][attribute].score) {
        className += ' active';
      }
      return (
        <li key={score.score} className={ className }>
          <div className='wrapper'>
            <div className='number'>{ score.score }</div>
            <div className='text'>{ score.definition }</div>
          </div>
        </li>
      );
    });
  }
  render () {
    if (!this.props.cow) return null;
    const attribute = this.props.match.params.attribute;
    const title = getName(attribute, this.props.scoreDefinitions);
    return (
      <div id='cow-score-card'
        data-cy={ `score-card-${this.props.match.params.attribute}` }>
        <article className='content' ref={this.modalRef}>
          <div
            className="close-wrapper"
            ref={this.closeRef}
            onClick={ this.close }>
            <CloseIcon testId="score-card-close-icon"/>
          </div>
          <h4 className='mini-title'>what does it mean?</h4>
          <h1 className='title'>{ title }</h1>
          <p className='significance'>
            { getSignificance(
              this.props.match.params.attribute,
              this.props.scoreDefinitions) }
          </p>
          <ul className='scores'>
            { this.renderScores() }
          </ul>
        </article>
      </div>
    );
  }
}

CowScoreCard.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      attribute: PropTypes.string.isRequired
    // cowId: PropTypes.string.isRequired
    })
  }),
  cowId: PropTypes.string.isRequired,
  selectCow: PropTypes.func.isRequired,
  cow: PropTypes.object,
  scoreDefinitions: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(CowScoreCard);
